<template>
  <!-- Service Details Area Start -->
  <section class="ekifa-single-service-area section_80">
    <b-container>
      <b-row>
        <b-col md="4" lg="3">
          <div class="single-service-left">
            <div class="single-service-left-widget">
              <ul>
                <li>
                  <b-link to="/services">
                    all services
                    <i class="fa fa-angle-right"></i>
                  </b-link>
                </li>
                <li class="active">
                  <b-link to="/service-details">
                    Business accounting
                    <i class="fa fa-angle-right"></i>
                  </b-link>
                </li>
                <li>
                  <b-link to="/service-details">
                    Expert Team Mates
                    <i class="fa fa-angle-right"></i>
                  </b-link>
                </li>
                <li>
                  <b-link to="/service-details">
                    Consulting Idea
                    <i class="fa fa-angle-right"></i>
                  </b-link>
                </li>
                <li>
                  <b-link to="/service-details">
                    Startup Consultation
                    <i class="fa fa-angle-right"></i>
                  </b-link>

                </li>
                <li>
                  <b-link to="/service-details">
                    Text Analysis
                    <i class="fa fa-angle-right"></i>
                  </b-link>
                </li>
                <li>
                  <b-link to="/service-details">
                    Digital Marketing
                    <i class="fa fa-angle-right"></i>
                  </b-link>
                </li>
              </ul>
            </div>
            <div class="single-service-left-widget">
              <div class="widget-title">
                <h3>Our Brochures</h3>
              </div>
              <div class="broucher-item">
                <a href="#">Download.Pdf <span class="fa fa-long-arrow-right"></span></a>
                <i class="fa fa-file-pdf-o"></i>
              </div>
              <div class="broucher-item">
                <a href="#">Download.Doc <span class="fa fa-long-arrow-right"></span></a>
                <i class="fa fa-file-word-o"></i>
              </div>
            </div>
            <div class="single-service-left-widget">
              <div class="widget-title">
                <h3>get in touch</h3>
              </div>
              <p>The Associates - Law Firm, 40 street, <br> Beirut, Lebanon </p>
              <p><i class="fa fa-phone"></i> 012-927-4585-22</p>
              <p><i class="fa fa-envelope"></i> support@example.com</p>
              <p><i class="fa fa-globe"></i> www.example.com</p>
            </div>
          </div>
        </b-col>
        <b-col md="8" lg="9">
          <div class="single-service-left  margin-top">
            <div class="single-service-widget clearfix">
              <div class="single-service-text">
                <h2>{{ serviceInfo.title }}</h2>
                <p>{{ serviceInfo.description }}</p>
              </div>
            </div>
            <div class="single-service-widget clearfix">
              <div class="about-service-box">
                <div class="about-service-left">
                  <img :src="require('../assets/img/service-2.jpg')" alt="about service" />
                </div>
                <div class="about-service-right">
                  <h2>About Service</h2>
                  <p>{{ ServiceDetails.about }}</p>
                  <ul>
                    <li><i class="fa fa-check-circle"></i> Best Data Security</li>
                    <li><i class="fa fa-check-circle"></i> Qualified Guardst</li>
                    <li><i class="fa fa-check-circle"></i> Sequre Payment</li>
                  </ul>
                  <ul>
                    <li><i class="fa fa-check-circle"></i> Yeard of Protection</li>
                    <li><i class="fa fa-check-circle"></i> Unique Security </li>
                    <li><i class="fa fa-check-circle"></i> fire safety secure</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="single-service-widget clearfix">
              <h2>Popular Question</h2>
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab" id="headingOne">
                    <b-button v-b-toggle.collapseOne variant="info">
                      <i class="switch fa fa-plus"></i>
                      1. signiferumque Te nec dolor clita partem ?
                    </b-button>
                  </b-card-header>
                  <b-collapse id="collapseOne" role="tabpanel" class="p-1">
                    <b-card-body>
                      <b-card-text>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab" id="headingTwo">
                    <b-button v-b-toggle.collapseTwo variant="info">
                      <i class="switch fa fa-plus"></i>
                      2. Which Te signiferumque nec dolor partem clita ?
                    </b-button>
                  </b-card-header>
                  <b-collapse id="collapseTwo" role="tabpanel" class="p-1">
                    <b-card-body>
                      <b-card-text>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab" id="headingThree">
                    <b-button v-b-toggle.collapseThree variant="info">
                      <i class="switch fa fa-plus"></i>
                      3. What is the payment process ?
                    </b-button>
                  </b-card-header>
                  <b-collapse id="collapseThree" role="tabpanel" class="p-1">
                    <b-card-body>
                      <b-card-text>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab" id="headingfour">
                    <b-button v-b-toggle.collapseTwo variant="info">
                      <i class="switch fa fa-plus"></i>
                      4. How to get Appoinment ?
                    </b-button>
                  </b-card-header>
                  <b-collapse id="collapsefour" role="tabpanel" class="p-1">
                    <b-card-body>
                      <b-card-text>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Service Details Area End -->
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ServiceDetailsArea",
  props: {
    ServiceDetails: {
      title: '',
      description: '',
      about: '',
      dataPath: ''
    }
  },
  data() {
    return {
      serviceInfo: {}
    }
  },
  mounted(){
    this.load(this.$route.params.idService)
    this.serviceInfo = require('../json/Services/'+ this.service.data)
  },
  computed: {
    ...mapState([ 'service']),
  },
  methods: {
    ...mapActions(['loadService']),
    load( serviceName){
      this.loadService(serviceName)
    }
  }
}
</script>

<style scoped>

</style>